import React from 'react';
import IndexLayout from 'layouts';
import ContentSection from 'views/PrivacyPage/ContentSection';
import { Components } from 'sitedata';

const PrivacyPage = () => (
  <IndexLayout>
    <Components.Header />
    <ContentSection />
    <Components.Footer />
  </IndexLayout>
);

export default PrivacyPage;
