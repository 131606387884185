import React, { useState } from 'react';
import { Tabs } from 'components';
import { Components } from 'sitedata';
import { styled } from 'styles';

type TabKey = 'terms' | 'privacy';

type Tab = { key: TabKey; text: string };

const tabs: Tab[] = [
  { key: 'terms', text: 'Terms of Service' },
  { key: 'privacy', text: 'Privacy Policy' }
];

const ContentSection = () => {
  const [activeTab, setActiveTab] = useState<TabKey>('terms');

  const getTabContent = (key: TabKey) => {
    switch (key) {
      case 'terms':
        return <Components.TermsContent />;
      case 'privacy':
        return <Components.PrivacyContent />;
      default:
        return null;
    }
  };
  return (
    <StyledContentSection className="privacy-section">
      <Tabs
        activeTab={activeTab}
        onSelect={(_e, value) => setActiveTab(value.key)}
        items={tabs}
        centered
      />
      <div className="privacy-tabs-content">{getTabContent(activeTab)}</div>
    </StyledContentSection>
  );
};

const StyledContentSection = styled.div`
  position: relative;
  padding: 48px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:after {
    content: '';
    width: calc(100% - 32px);
    height: 1px;
    background: ${props => props.theme.colors.lightGray};
    position: absolute;
    bottom: 0;
    left: 16px;
  }
  .privacy-tabs-content {
    max-width: 616px;
    padding: 32px 0 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 24px 16px 48px;

    .privacy-tabs-content {
      max-width: 100%;
    }
  }
`;

export default ContentSection;
